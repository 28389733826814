<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card class="elevation-12">
      <v-toolbar color="primary" dark dense flat
        ><h3>Registro exitoso 😎</h3></v-toolbar
      >
      <v-card-text>
        <div class="text--primary" style="padding-top: 10px;">
          Para activar tu cuenta, favor de verificar el correo que ha sido
          enviado a {{ correo }}
        </div>
      </v-card-text>
      <v-card-text
        >Si no encuentra el correo, revise en la sección de "No deseado" o
        "Spam" y márquelo como correo deseado.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          dark
          small
          @click="$emit('redireccionar')"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "registroExitoso",
  props: ["mostrar","correo"]
};
</script>
<template>
  <v-app id="inspire">
    <v-container fluid fill-height style="background-color: #0d3863">
      <v-layout align-center justify-space-around row>
        <v-row justify="center">
          <v-col sm="6" xs="12" md="4">
            <v-card class="elevation-12">
              <v-img
                src="images/agnus_cursos.jpg"
                class="white--text align-end"
              >
                <v-card-title>Registro de usuario</v-card-title></v-img
              >

              <v-layout align-center justify-center>
                <v-row>
                  <v-col cols="12">
                    <v-form v-on:submit.prevent>
                      <v-card class="elevation-0">
                        <v-card-text>
                          <v-text-field
                            v-model.trim="correo"
                            name="login_correo"
                            label="Correo"
                            type="text"
                            outlined
                            dense
                            :disabled="loading"
                            :loading="validandoCorreo"
                            :error-messages="correoErrors"
                            @change="validarCorreoRegistro()"
                            @input="validarCorreo()"
                            @blur="validarCorreo()"
                          ></v-text-field>

                          <v-text-field
                            v-model.trim="nombre"
                            label="Nombre"
                            type="text"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="nombreErrors"
                            @input="validarNombre()"
                            @blur="validarNombre()"
                          ></v-text-field>
                          <v-text-field
                            v-model.trim="paterno"
                            label="Apellido Paterno"
                            type="text"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="paternoErrors"
                            @input="validarPaterno()"
                            @blur="validarPaterno()"
                          ></v-text-field>
                          <v-text-field
                            v-model.trim="materno"
                            type="text"
                            label="Apellido Materno"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="maternoErrors"
                            @input="validarMaterno()"
                            @blur="validarMaterno()"
                          ></v-text-field>

                          <v-text-field
                            v-model="contrasena"
                            name="login"
                            type="password"
                            label="Constraseña"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="contrasenaErrors"
                            @input="validarContrasena()"
                            @blur="validarContrasena()"
                          ></v-text-field>
                          <v-text-field
                            v-model="confirmar"
                            name="login"
                            label="Confirmar Contraseña"
                            type="password"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="confirmarErrors"
                            @input="validarConfirmar()"
                            @blur="validarConfirmar()"
                          ></v-text-field>

                          <vue-recaptcha
                            sitekey="6LdwLLYZAAAAADRkHh_ptXA6rtFW-4s2I2hTPzzV"
                            :loadRecaptchaScript="true"
                            ref="recaptcha"
                            @verify="verifyRecaptcha"
                            @expired="recaptchaToken = null"
                            @error="recaptchaToken = null"
                          >
                          </vue-recaptcha>
                        </v-card-text>
                        <v-card-text>
                          <div style="padding-top: 10px" class="text-center">
                            Al registrarse acepta: <br>
                            <legalDialogComponent
                              title="Aviso de privacidad, términos y condiciones"
                              clave="TYC"
                            /><br />
                            <legalDialogComponent
                              title="Aviso de confidencialidad"
                              clave="ACON"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            type="submit"
                            color="primary"
                            dark
                            :loading="loading"
                            :class="{ 'disable-events': !formularioValido }"
                            block
                            @click="registrarUsuario()"
                            >Registrar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-col>
                </v-row>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
      <registroExitosoComponent
        :mostrar="registroExitoso"
        :correo="correo"
        @redireccionar="$router.push('/login')"
      />
      <systemMessageComponent />
    </v-container>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

import systemMessageComponent from "../globals/template/systemMessage.vue";
import registroExitosoComponent from "./registroExitoso.vue";
import legalDialogComponent from "./legalDialog.vue";

import {
  validarCorreoRegistroService,
  registrarUsuarioService,
} from "./login.service";

export default {
  name: "registroView",
  components: {
    systemMessageComponent,
    registroExitosoComponent,
    VueRecaptcha,
    legalDialogComponent,
  },
  mixins: [validationMixin],
  computed: {
    formularioValido() {
      if (this.correoErrors.length > 0) return false;
      if (this.nombreErrors.length > 0) return false;
      if (this.paternoErrors.length > 0) return false;
      if (this.maternoErrors.length > 0) return false;
      if (this.contrasenaErrors.length > 0) return false;
      if (this.confirmarErrors.length > 0) return false;
      return true;
    },
  },
  validations: {
    correo: { required, email },
    nombre: { required, minLength: minLength(2) },
    paterno: { required, minLength: minLength(2) },
    materno: { required, minLength: minLength(2) },
    contrasena: { required, minLength: minLength(5) },
    confirmar: { sameAs: sameAs("contrasena"), required },
  },
  data() {
    return {
      registroExitoso: false,
      loading: false,
      correo: "",
      validandoCorreo: false,
      correoErrors: [],
      nombreErrors: [],
      paternoErrors: [],
      maternoErrors: [],
      contrasenaErrors: [],
      confirmarErrors: [],
      nombre: "",
      materno: "",
      paterno: "",
      contrasena: "",
      confirmar: "",
      recaptchaToken: null,
    };
  },
  methods: {
    validarCorreo() {
      this.correoErrors = [];
      !this.$v.correo.required && this.correoErrors.push("Campo requerido.");
      !this.$v.correo.email && this.correoErrors.push("Correo no válido.");
    },
    validarNombre() {
      this.nombreErrors = [];
      !this.$v.nombre.required && this.nombreErrors.push("Campo requerido.");
      !this.$v.nombre.minLength &&
        this.nombreErrors.push("Mínimo 2 caracteres.");
    },
    validarPaterno() {
      this.paternoErrors = [];
      !this.$v.paterno.required && this.paternoErrors.push("Campo requerido.");
      !this.$v.paterno.minLength &&
        this.paternoErrors.push("Mínimo 2 caracteres.");
    },
    validarMaterno() {
      this.maternoErrors = [];
      !this.$v.materno.required && this.maternoErrors.push("Campo requerido.");
      !this.$v.materno.minLength &&
        this.maternoErrors.push("Mínimo 2 caracteres.");
    },
    validarContrasena() {
      this.contrasenaErrors = [];
      !this.$v.contrasena.required &&
        this.contrasenaErrors.push("Campo requerido.");
      !this.$v.contrasena.minLength &&
        this.contrasenaErrors.push("Mínimo 5 caracteres.");
    },
    validarConfirmar() {
      this.confirmarErrors = [];
      !this.$v.confirmar.required &&
        this.confirmarErrors.push("Campo requerido.");
      !this.$v.confirmar.sameAs &&
        this.confirmarErrors.push("Deben ser iguales");
    },
    async validarCorreoRegistro() {
      this.validarCorreo();
      if (this.correoErrors.length == 0) {
        this.validandoCorreo = true;
        const sendData = { correo: this.correo };
        const serverResponse = await validarCorreoRegistroService(sendData);
        if (serverResponse) {
          this.validandoCorreo = false;
          if (!serverResponse.ok) {
            if (serverResponse.correoError)
              this.correoErrors.push(serverResponse.correoError);
            else this.correoErrors.push("No se pudo validar el correo");
          }
        }
      }
    },
    verifyRecaptcha(response) {
      this.recaptchaToken = response;
    },
    async registrarUsuario() {
      this.$v.$touch();
      if (this.formularioValido) {
        try {
          const data = {
            correo: this.correo,
            nombre: this.nombre,
            paterno: this.paterno,
            materno: this.materno,
            contrasena: this.contrasena,
            confirmar: this.confirmar,
            recaptchaToken: this.recaptchaToken,
          };
          this.loading = true;
          const serverResponse = await registrarUsuarioService(data);
          this.loading = false;
          if (serverResponse) {
            if (!serverResponse.ok) {
              if (serverResponse.correoError)
                this.correoErrors.push(serverResponse.correoError);
              if (serverResponse.nombreError)
                this.nombreErrors.push(serverResponse.nombreError);
              if (serverResponse.paternoError)
                this.paternoErrors.push(serverResponse.paternoError);
              if (serverResponse.maternoError)
                this.maternoErrors.push(serverResponse.maternoError);
              if (serverResponse.contrasenaError)
                this.contrasenaErrors.push(serverResponse.contrasenaError);
              if (serverResponse.confirmarError)
                this.confirmarErrors.push(serverResponse.confirmarError);
            } else {
              this.registroExitoso = true;
            }
          }
        } catch (error) {
          this.loading = false;
          if (!error.handdled)
            this.$appErrorMessage("Ocurrió un error al realizar el registro.");
        }
      }
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}

#logo {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.invalid_input {
  border: 1px solid red;
}

.link {
  text-decoration: none;
}
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('a',{staticClass:"link",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(_vm._s(_vm.title))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[(_vm.loading)?_c('span',[_vm._v("Cargando...")]):_c('span',[_vm._v(_vm._s(_vm.title))])]),_c(VSpacer)],1),_c('br'),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.content)}}),_c(VCardText,[_vm._v("Última actualización: "+_vm._s(_vm.lastUpdate))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
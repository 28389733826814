<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{}">
      <a href="javascript: void(0);" class="link" @click="dialog = true">{{
        title
      }}</a>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span v-if="loading">Cargando...</span>
          <span v-else>{{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <br />
      <v-card-text v-html="content"></v-card-text>
      <v-card-text>Última actualización: {{ lastUpdate }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { contenidoLegalService } from "./login.service";
export default {
  props: {
    title: String,
    clave: String,
  },
  watch: {
    dialog(value) {
      if (value) this.cargarContenidoLegal();
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      content: "",
      lastUpdate: "",
    };
  },
  methods: {
    async cargarContenidoLegal() {
      try {
        this.loading = true;
        const serverResponse = await contenidoLegalService(this.clave);
        this.loading = false;
        if (!serverResponse.ok) this.$appErrorMessage();
        else {
          this.content = serverResponse.contenido;
          let date = DateTime.fromISO(serverResponse.actualizacion);
          this.lastUpdate = date.toLocaleString(DateTime.DATE_FULL);
        }
      } catch (error) {
        console.log(error);
        this.$appErrorMessage();
      }
    },
  },
};
</script>
